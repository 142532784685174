import { webMercatorToGeographic } from '@arcgis/core/geometry/support/webMercatorUtils';
import { ESRI, GOOGLE } from '@rsos/maps/constants/mapProviders';
import exportToGlobalNamespace from '@rsos/utils/exportToGlobalNamespace';

/**
 * Get the current Esri map view state.
 * @param {String} esriBasemap - The Esri basemap to be saved in the `mapViewState` object.
 * @returns Object
 */
export const getEsriMapViewState = (esriBasemap = '') => {
  const mapProvider = window?.__RSOS?.map?.mapProvider;

  const mapInstance = window?.__RSOS?.map?.mapInstance;

  let viewState = null;

  if (mapProvider === ESRI && mapInstance) {
    const { latitude, longitude } = mapInstance.view.center;

    const { extent, width, height } = mapInstance.view;

    const convertedExtent = webMercatorToGeographic(extent);

    const { xmax, xmin, ymax, ymin } = convertedExtent;

    const bounds = {
      north: ymax,
      east: xmax,
      south: ymin,
      west: xmin,
    };

    viewState = {
      bounds,
      extent: convertedExtent,
      esriBasemap,
      latitude,
      longitude,
      provider: ESRI,
      width,
      height,
      zoom: mapInstance.view.zoom || null,
    };
  }

  return viewState;
};

/**
 * Get the current Google map view state.
 * @param {String} esriBasemap - The Esri basemap to be saved in the `mapViewState` object.
 * @returns Object
 */
export const getGoogleMapViewState = (esriBasemap = '') => {
  const mapProvider = window?.__RSOS?.map?.mapProvider;

  const mapInstance = window?.__RSOS?.map?.mapInstance;

  let viewState = null;

  if (mapProvider === GOOGLE && mapInstance) {
    const center = mapInstance.getCenter();

    const coordinates = new window.google.maps.LatLng(
      center.lat(),
      center.lng(),
    );

    const latitude = coordinates.lat();

    const longitude = coordinates.lng();

    const width = mapInstance.getDiv().offsetWidth;

    const height = mapInstance.getDiv().offsetHeight;

    const bounds = {
      north: mapInstance.getBounds().getNorthEast().lat(),
      east: mapInstance.getBounds().getNorthEast().lng(),
      south: mapInstance.getBounds().getSouthWest().lat(),
      west: mapInstance.getBounds().getSouthWest().lng(),
    };

    const extent = {
      xmin: mapInstance.getBounds().getNorthEast().lng(),
      ymin: mapInstance.getBounds().getSouthWest().lat(),
      xmax: mapInstance.getBounds().getSouthWest().lng(),
      ymax: mapInstance.getBounds().getNorthEast().lat(),
    };

    viewState = {
      bounds,
      extent,
      esriBasemap,
      latitude,
      longitude,
      provider: GOOGLE,
      width,
      height,
      zoom: mapInstance.getZoom() || null,
    };

    return viewState;
  }
};

/**
 * Get the current map view state and export it to the global namespace - window.__RSOS.mapViewState.
 * @param {String} esriBasemap - The Esri basemap to be saved in the `mapViewState` object.
 * @returns Object
 */
export const exportMapViewState = (esriBasemap = '') => {
  const mapProvider = window?.__RSOS?.map?.mapProvider;

  let viewState = null;

  if (mapProvider === ESRI) {
    viewState = getEsriMapViewState(esriBasemap);
  } else if (mapProvider === GOOGLE) {
    viewState = getGoogleMapViewState(esriBasemap);
  }

  exportToGlobalNamespace('mapViewState', { ...viewState });

  return viewState;
};
