import React, { useState, useEffect } from 'react';
import Recaptcha from 'react-recaptcha';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Button, Text, Box } from '@rsos/components/capstone/base';
import InputText from '@rsos/components/capstone/base/form/inputs/InputText';
import { getUrlParameter } from '@rsos/utils';
import { getRecaptchaSiteKey } from '@rsos/utils/metaTags';
import { notifyError } from '@rsos/utils/toastUtils';
import usePrevious from '@rsos/utils/usePrevious';
import { confirmUser, getOAuthToken, resetFailedCount } from '../../../actions';
import { LoginWrapper } from './Login.styles.js';

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const isLoading = useSelector(
    state => state.sinatra.auth.loading || state.sinatra.user.loading,
  );

  const isGettingPSAPInfo = useSelector(state => state.psaps.isGettingPSAPInfo);
  const failedCount = useSelector(state => state.auth.failedCount);
  const failedCountExpiration = useSelector(
    state => state.auth.failedCountExpiration,
  );

  const isLoggingIn = isLoading || isGettingPSAPInfo;
  const [verifiedRecaptcha, setVerifiedRecaptcha] = useState(false);
  const [recaptchaError, setRecaptchaError] = useState(false);
  let recaptchaInstance;

  useEffect(() => {
    checkFailedCount();
    const token = getUrlParameter('token');
    if (token) {
      confirmUser(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const prevFailedCountExpiration = usePrevious(failedCountExpiration);

  useEffect(() => {
    if (
      failedCount < 5 ||
      (prevFailedCountExpiration < failedCountExpiration && failedCount >= 5)
    ) {
      checkFailedCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [failedCount, failedCountExpiration]);

  const checkFailedCount = () => {
    if (failedCount > 0) {
      // If a timer already exists, reset it
      if (checkFailedCountTimer !== undefined) {
        clearTimeout(checkFailedCountTimer);
      }
      const checkFailedCountTimer = setTimeout(() => {
        dispatch(resetFailedCount());
      }, failedCountExpiration - new Date().getTime());
    }
  };

  const expiredCallback = () => {
    notifyError(
      ' Recaptcha verification has expired. Recheck the checkbox to\n' +
        ' continue.',
    );
    setVerifiedRecaptcha(false);
  };
  const onloadCallback = () => {
    // It's required to have render="explicit" and onloadCallback otherwise
    // Uncaught Error: ReCAPTCHA placeholder element must be empty
    // will be thrown
  };

  const verifyCallback = () => {
    setRecaptchaError(false);
    setVerifiedRecaptcha(true);
  };
  const handleSubmit = values => {
    const email = values.email.split(' ').join('').trim();
    if (failedCount < 5 || verifiedRecaptcha) {
      dispatch(getOAuthToken(email, values.password, navigate));
    } else {
      if (recaptchaError) {
        notifyError('Please check the recaptcha checkbox.');
      } else if (verifiedRecaptcha) {
        dispatch(getOAuthToken(email, values.password, null));
      } else {
        setRecaptchaError(true);
        recaptchaInstance.reset();
      }
    }
  };
  return (
    <LoginWrapper>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        {({ values, handleBlur, handleChange }) => (
          <Form name="login-form" style={{ width: '100%' }}>
            <Box flow={32}>
              <Box>
                <Text size="normal" color="primaryText" mb="8px">
                  Email
                </Text>
                <InputText
                  id="email"
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  shouldDisplayClearButton={false}
                  maxLength={50}
                  enableLastPass
                />
              </Box>
              <Box>
                <Box horizontal justify="space-between">
                  <Text size="normal" color="primaryText" mb="8px">
                    Password
                  </Text>
                  <Link
                    id="forgotPassword"
                    to="/request-password"
                    style={{ textDecoration: 'none' }}
                  >
                    <Text color="primaryBase" size="small">
                      Forgot password?
                    </Text>
                  </Link>
                </Box>
                <InputText
                  id="password"
                  name="password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  shouldDisplayClearButton={false}
                  maxLength={256}
                  enableLastPass
                />
              </Box>
              {failedCount >= 5 && (
                <Recaptcha
                  expiredCallback={expiredCallback}
                  ref={ref => {
                    recaptchaInstance = ref;
                  }}
                  render="explicit"
                  sitekey={getRecaptchaSiteKey()}
                  type="image"
                  onloadCallback={onloadCallback}
                  verifyCallback={verifyCallback}
                />
              )}
              <Button
                btnType="primary"
                type="submit"
                size="large"
                noMargin
                isLoadingProp={isLoggingIn}
                disabled={
                  isLoggingIn || (failedCount >= 5 && !verifiedRecaptcha)
                }
              >
                Log in
              </Button>
              <Link id="loginToSignUp" to="/sign-up">
                <Button
                  btnType="secondary"
                  size="large"
                  noMargin
                  grow
                  mt={-3} // cannot override mt of Link (already set to 32px !important)
                >
                  New Agency Sign Up
                </Button>
              </Link>

              <hr />

              <Link id="loginWithSSO" to="/sso">
                <Button
                  btnType="tertiary"
                  size="large"
                  noMargin
                  grow
                  mt="-25px" // cannot override mt of Link (already set to 32px !important)
                >
                  Sign in with SSO
                </Button>
              </Link>

              <Text
                color="secondary"
                size="small"
                textAlign={'center'}
                mt={'24px !important'}
              >
                By signing up or logging in, you agree to be bound by the&nbsp;
                <Link
                  id="PortalEULA"
                  to="https://rapidsos.com/ecc-end-user-license/"
                  target="_blank"
                  style={{
                    textDecoration: 'underline',
                    display: 'inline-block',
                    color: 'inherit',
                  }}
                >
                  <Text color="secondary" size="small">
                    UNITE EULA
                  </Text>
                </Link>
                &nbsp;and&nbsp;
                <Link
                  id="privacyPolicy"
                  to="https://rapidsos.com/legal/emergency-related-services-privacy-policy"
                  target="_blank"
                  style={{
                    textDecoration: 'underline',
                    display: 'inline-block',
                    color: 'inherit',
                  }}
                >
                  <Text color="secondary" size="small">
                    Privacy Policy
                  </Text>
                </Link>
              </Text>
            </Box>
          </Form>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default Login;
