export const GPX_EVENT_TYPES = {
  CLICK: 'click',
  API_RESPONSE: 'api_response',
};

export const GPX_CAPABILITIES = {
  VIDEO_STREAMING: 'video_streaming',
  MEDIA_UPLOAD: 'media_upload',
};

export const GPX_FEATURES = {
  CIRCUS_LIVE_VIDEO: 'circus_live_video',
  CIRCUS_MEDIA_UPLOAD: 'circus_media_upload',
  CIRCUS_DISPLAY_MEDIA: 'circus_display_media',
};

export const GPX_PARTNERS = {
  CIRCUS: 'circus',
};

export const GPX_ROUTES = {
  MEDIA: 'Media',
};

// Freeze the enum to prevent modifications
Object.freeze(GPX_EVENT_TYPES);
Object.freeze(GPX_CAPABILITIES);
Object.freeze(GPX_FEATURES);
Object.freeze(GPX_PARTNERS);
