import md5 from 'md5';
import { getSessionAge } from '@rsos/base-utils/metaTags';
import { getAPIHost } from '@rsos/utils/metaTags';
import { captureExceptionWithScope } from '@rsos/utils/sentry';
import { JURISDICTION_VIEW } from './constants/capabilities';
import {
  GPX_EVENT_TYPES,
  GPX_FEATURES,
  GPX_CAPABILITIES,
  GPX_PARTNERS,
  GPX_ROUTES,
} from './constants/gpxEventEnums';

export const CUSTOM_EVENT_NAME = 'UNITE_USAGE';

export const GSPX_LAST_IDENTIFIED = 'GSPX_LAST_IDENTIFIED';

export const PRODUCTION_HOSTNAMES = [
  'rapidlite.rapidsos.com',
  'rapidsosportal.com',
];

export const GSPX_TRACKING_IDS = {
  prod: 'AP-PEXDVMYJXIFL-2',
  staging: 'AP-PEXDVMYJXIFL-2-2', //staging and sandbox
  qa: 'AP-PEXDVMYJXIFL-2-3',
  dev: 'AP-PEXDVMYJXIFL-2-4', // This is the key for dev
};

/**
 * Returns the corresponding GainSightPX tag key for the stack (non-prod or
 * prod).
 */
export const determineGSPXTrackingID = () => {
  const { hostname } = window.location;
  let gspxKey = GSPX_TRACKING_IDS.dev;
  const found = PRODUCTION_HOSTNAMES.find(prod => hostname === prod);
  if (found || hostname.includes('prod')) {
    gspxKey = GSPX_TRACKING_IDS.prod;
  } else if (hostname.includes('staging') || hostname.includes('sandbox')) {
    gspxKey = GSPX_TRACKING_IDS.staging;
  } else if (hostname.includes('qa')) {
    gspxKey = GSPX_TRACKING_IDS.qa;
  } else {
    gspxKey = GSPX_TRACKING_IDS.dev;
  }
  return gspxKey;
};

class CapstoneGainSightPX {
  constructor() {
    this.store = null;
    this.trackingID = determineGSPXTrackingID();
  }

  addIdentifier(userInfo = {}, accountInfo = {}) {
    try {
      window.aptrinsic('identify', userInfo, accountInfo);
      localStorage.setItem(GSPX_LAST_IDENTIFIED, Math.floor(Date.now() / 1000));
    } catch {
      //eslint-disable-next-line no-empty
    }
  }

  identify() {
    try {
      this.checkStore();

      // Being defensive here and checking against the session's start in the
      // rare event that the flag was not cleaned up in localStorage on logout.
      // If localStorage clears correctly, then it's enough to just check if
      // the flag is set.
      const sessionAge = getSessionAge();
      const lastIdentified = localStorage.getItem(GSPX_LAST_IDENTIFIED);
      const identifyStart = Date.now() / 1000 - sessionAge;

      // If the user has already been identified, don't identify again.
      if (lastIdentified && lastIdentified > identifyStart) {
        return;
      }

      const currentState = this.store?.getState();

      const user = currentState?.sinatra?.user;
      const psap = currentState?.psaps?.currentPSAP;

      const isJVEnabled = !!psap?.active_capabilities[JURISDICTION_VIEW];

      const userInfo = this.normalizeGSPXUserInfo(user);
      const accountInfo = {
        id: psap.account_id,
        name: psap.display_name,
        PrimaryPointOfContact: psap.contact_email,
        JurisdictionViewEnabled: isJVEnabled,
        AddressablePopulation: psap.population,
        AgencyState: psap.state,
        DispatchType: psap.dispatch_type,
      };

      this.addIdentifier(userInfo, accountInfo);
    } catch (error) {
      captureExceptionWithScope(new Error('Failed to identify user'), {
        error,
      });
    }
  }

  checkStore() {
    // The following should only be shown during development.
    if (!this.store && process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error(
        'The reference to the store was not set. First call initialize().',
      );
    }
  }

  /**
   * Returns an MD5 hash of the current user's email.
   */
  getHashedEmail() {
    if (this.store === null) {
      return '';
    }
    const { sinatra } = this.store?.getState();
    const { email } = sinatra.user.profile;

    let hashedEmail;
    if (email) {
      hashedEmail = md5(email);
    }

    return hashedEmail;
  }

  /**
   * Initializes store with the corresponding tracking key for the
   * current stack.
   * @param {Object} store - A reference to the redux store instance.
   */
  initialize(store) {
    this.store = store;
  }

  normalizeGSPXUserInfo(userState) {
    let storeRef = this.store;
    let user = userState;
    if (!userState) {
      user = storeRef?.getState().sinatra?.user;
      captureExceptionWithScope(new Error('Undefined user'), {
        userState,
      });
    }

    const role = user.currentRole;
    const {
      date_joined,
      email,
      first_name,
      id,
      last_active_session,
      last_name,
    } = user.profile;

    let profileID = id;
    const appName = role.application;
    const appVersion = process.env.REACT_APP_FE_VERSION || '';
    const hashedEmail = this.getHashedEmail() || '';
    const formattedDateJoined = Date.parse(date_joined);
    const formattedLastActive = Date.parse(last_active_session);
    const host = getAPIHost();

    if (profileID === undefined) {
      user = storeRef.getState().sinatra?.user;
      profileID = storeRef.getState().sinatra?.user?.profile.id;
    }

    let profileIDWithStack = profileID;
    if (this.trackingID === GSPX_TRACKING_IDS.staging) {
      profileIDWithStack = `${profileID}-staging`;
    } else if (this.trackingID === GSPX_TRACKING_IDS.qa) {
      profileIDWithStack = `${profileID}-qa`;
    } else if (this.trackingID === GSPX_TRACKING_IDS.dev) {
      profileIDWithStack = `${profileID}-dev`;
    } else {
      profileIDWithStack = `${profileID}-prod`;
    }

    return {
      appName,
      appVersion,
      email,
      firstName: first_name,
      hashedEmail,
      host,
      id: profileIDWithStack,
      lastActive: formattedLastActive,
      lastName: last_name,
      signUpDate: formattedDateJoined,
      trackingID: this.trackingID,
      userRole: role.name,
    };
  }

  /**
   * Track Custom event
   * @param {string} category - app and category that calling the tracking
   * event, could be Data Map, Data CallQueue, like the component name we use
   * @param {object} eventInfo - any info that you want to be tracked
   * @param {number} eventInfo.'Launched date' - timestamp representing when
   * the event was triggered.
   */
  trackCustomEvent(category, eventInfo) {
    try {
      if (window.aptrinsic) {
        if (eventInfo && !eventInfo['Launched date']) {
          eventInfo['Launched date'] = new Date();
        }
        if (eventInfo && !eventInfo['Category']) {
          eventInfo['Category'] = category;
        }
        const sinatra = this.store?.getState().sinatra;
        const userID = sinatra?.user?.profile?.id;
        const psap = this.store?.getState()?.psaps?.currentPSAP;
        const psapID = psap?.account_id;

        if (sinatra?.irp?.isICSP && eventInfo && !eventInfo['irpVersion']) {
          eventInfo['irpVersion'] = sinatra?.irp?.irpVersion;
        }

        if (userID && eventInfo && !eventInfo['user_id']) {
          eventInfo['user_id'] = userID;
        }
        if (psapID && eventInfo && !eventInfo['ecc_account_ids']) {
          eventInfo['ecc_account_ids'] = {};
          eventInfo.ecc_account_ids.source = psapID;
        }
        //the first parameter has to be 'track' for custom event
        window.aptrinsic('track', category, eventInfo);
      }
      //eslint-disable-next-line no-empty
    } catch { }
  }

  /**
   * Helper function to validate if a value exists in an enum object
   * @param {string} value - The value to validate
   * @param {Object} enumObject - The enum object containing valid values
   * @param {string} fieldName - The name of the field being validated
   * @throws {Error} If the value is invalid
   */
  validateEnumValue(value, enumObject, fieldName) {
    const validValues = Object.values(enumObject);
    if (!validValues.includes(value)) {
      throw new Error(`Invalid ${fieldName}: ${value}. Must be one of: ${validValues.join(', ')}`);
    }
  }

  /**
   * Helper function to validate required fields
   * @param {string} value - The value to validate
   * @param {string} fieldName - The name of the field being validated
   * @throws {Error} If the value is missing
   */
  validateRequiredField(value, fieldName) {
    if (!value) {
      throw new Error(`${fieldName} is required`);
    }
  }

  /**
   * Helper function to validate Unix timestamp
   * @param {number} timestamp - The timestamp to validate
   * @throws {Error} If the timestamp is invalid
   */
  validateTimestamp(timestamp) {
    if (timestamp) {
      // Check if it's a number and a valid Unix timestamp (between 1970 and 2100)
      if (!Number.isInteger(timestamp) ||
          timestamp < 0 ||
          timestamp > 4102444800000) { // Year 2100
        throw new Error('Invalid timestamp: must be a valid Unix timestamp in milliseconds');
      }
    }
  }

  /**
   * Helper function to validate ECC Account IDs
   * @param {Object.<string, string>} eccAccountIDs - Dictionary of ECC account IDs
   * @throws {Error} If the eccAccountIDs format is invalid
   */
  validateEccAccountIDs(eccAccountIDs) {
    // Check if it's an object first
    if (!eccAccountIDs || typeof eccAccountIDs !== 'object' || Array.isArray(eccAccountIDs)) {
      throw new Error('eccAccountIDs must be an object');
    }

    // If not empty, must contain 'source' key
    if (Object.keys(eccAccountIDs).length > 0 && !('source' in eccAccountIDs)) {
      throw new Error('eccAccountIDs must contain a "source" key when not empty');
    }

    // Validate all values are strings
    for (const [key, value] of Object.entries(eccAccountIDs)) {
      if (typeof value !== 'string') {
        throw new Error(`eccAccountIDs values must be strings, found invalid value for key "${key}"`);
      }
    }
  }

  /**
   * Helper function to validate object
   * @param {Object} obj - Dictionary object
   * @param {string} objName - Name of the object for error messages
   * @throws {Error} If the input is not an object
   */
  validateObject(obj, objName) {
    // Check if it's an object first
    if (!obj || typeof obj !== 'object' || Array.isArray(obj)) {
      throw new Error(`${objName} must be an object`);
    }
  }

  /**
   * Tracks a custom event in GainSight PX analytics platform.
   *
   * Required Parameters:
   * @param {string} name - The name of the event
   * @param {GPX_EVENT_TYPES[keyof GPX_EVENT_TYPES]} type - The type of the event. Must be one of GPX_EVENT_TYPES values
   * @param {GPX_FEATURES[keyof GPX_FEATURES]} feature - The feature associated with the event. Must be one of GPX_FEATURES values
   * @param {GPX_CAPABILITIES[keyof GPX_CAPABILITIES]} capability - The capability being used. Must be one of GPX_CAPABILITIES values
   * @param {GPX_ROUTES[keyof GPX_ROUTES]} route - The current route path. Must be one of GPX_ROUTES values
   *
   * Optional Parameters:
   * @param {number} [timestamp] - Unix timestamp of the event. Defaults to current time
   * @param {GPX_PARTNERS[keyof GPX_PARTNERS]} [partner] - Partner identifier if applicable. Must be one of GPX_PARTNERS values
   * @param {string} [sessionID] - Session identifier. Falls back to auth state session ID
   * @param {string} [userID] - User identifier. Falls back to current user's ID
   * @param {Object.<string, string>} [eccAccountIDs] - ECC account identifiers
   * @param {Object} [additionalKeys] - Additional custom key-value pairs
   * @param {Object} [entities] - Related entity information
   *
   * @throws {Error} When required parameters are missing
   * @throws {Error} When tracking service is not initialized
   * @throws {Error} When type, feature, capability, partner or route are not valid enum values
   * @throws {Error} When timestamp is invalid
   * @throws {Error} When eccAccountIDs is invalid
   * @throws {Error} When additionalKeys is not an object
   * @throws {Error} When entities is not an object
   *
   * @example
   * trackEvent(
   *   'request_video_stream',
   *   GPX_EVENT_TYPES.CLICK,
   *   GPX_FEATURES.CIRCUS_LIVE_VIDEO,
   *   GPX_CAPABILITIES.VIDEO_STREAMING,
   *   GPX_ROUTES.MEDIA,
   * );
   */
  trackEvent(
      name,
      type,
      feature,
      capability,
      route,
      timestamp = undefined,
      partner = undefined,
      sessionID = undefined,
      userID = undefined,
      eccAccountIDs = undefined,
      additionalKeys = undefined,
      entities = undefined
  ) {
    try {
        // Validate required parameters are present
        this.validateRequiredField(type, 'Event type');
        this.validateRequiredField(feature, 'Feature');
        this.validateRequiredField(capability, 'Capability');
        this.validateRequiredField(route, 'Route');

        // Validate enum values
        this.validateEnumValue(type, GPX_EVENT_TYPES, 'event type');
        this.validateEnumValue(feature, GPX_FEATURES, 'feature');
        this.validateEnumValue(capability, GPX_CAPABILITIES, 'capability');
        this.validateEnumValue(route, GPX_ROUTES, 'route');

        if (partner) {
            this.validateEnumValue(partner, GPX_PARTNERS, 'partner');
        }

        // Validate timestamp
        this.validateTimestamp(timestamp);

        // Check if tracking is available
        if (!window.aptrinsic) {
            throw new Error('Tracking service not initialized');
        }

        // Get current state safely
        const state = this.store?.getState();
        const sinatra = state?.sinatra;
        const storedUserID = sinatra?.user?.profile?.id;
        const psap = state?.psaps?.currentPSAP;
        const psapID = psap?.account_id;
        const authState = state?.auth;
        const storedSessionID = authState?.sessionId;

        // Prepare user ID, session ID, ECC account IDs, additional keys and entities
        const finalUserID = userID ?? storedUserID;
        const finalSessionID = sessionID ?? storedSessionID;
        const finalEccAccountIDs = eccAccountIDs ?? (psapID ? { source: psapID } : {});
        const finalAdditionalKeys = additionalKeys ?? {};
        const finalEntities = entities ?? {};

        // Validate ECC Account IDs
        this.validateEccAccountIDs(finalEccAccountIDs);

        // Validate additional keys and entities
        this.validateObject(finalAdditionalKeys, 'additionalKeys');
        this.validateObject(finalEntities, 'entities');

        // Construct event info with current timestamp
        const eventInfo = {
            event_name: name,
            event_type: type,
            feature,
            capability,
            route,
            event_timestamp: timestamp ?? Date.now(),
            ...(partner && { partner }),
            ...(finalSessionID && { session_id: finalSessionID }),
            ...(finalUserID && { user_id: finalUserID }),
            ecc_account_ids: finalEccAccountIDs,
            additional_keys: finalAdditionalKeys,
            entities: finalEntities
        };

        window.aptrinsic('track', CUSTOM_EVENT_NAME, eventInfo);
    } catch (error) {
        captureExceptionWithScope(
            new Error(`Failed to track GPX event: ${error.message}`),
            {
                error,
                context: {
                    name,
                    type,
                    feature,
                    capability,
                    route,
                    timestamp,
                }
            }
        );
    }
  }
}

/**
 * NOTE This returns a singleton of CapstoneGainSightPX
 */
export default new CapstoneGainSightPX();
