import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const sanitize = str => str?.replace(/[^a-zA-Z0-9 ]/g, '') || '';
export const BASE_ORIGIN_URL = 'rapidsosportal.com';
export const BASE_TARGET_URL = 'rapidsos.com';
export const IA_APP_NAME = 'ia';
export const DEFAULT_ENV = 'dev';
export const DEFAULT_NAMESPACE = 'delta';

export const useIntelligentAnalystURL = () => {
  const sinatra = useSelector(state => state.sinatra);
  const refresh_token = sinatra?.auth?.tokens?.refresh_token;

  const url = useMemo(() => {
    const [env, namespace] = window.location.hostname
      .split(BASE_ORIGIN_URL)[0]
      .split('-')
      .map(sanitize);

    if (env === 'localhost') {
      return `https://${IA_APP_NAME}-${DEFAULT_NAMESPACE}.${DEFAULT_ENV}.${BASE_TARGET_URL}`;
    }

    if (namespace && env) {
      return `https://${IA_APP_NAME}-${namespace}.${env}.${BASE_TARGET_URL}`;
    }

    if (env) {
      return `https://${IA_APP_NAME}.${env}.${BASE_TARGET_URL}`;
    }

    return `https://${IA_APP_NAME}.${BASE_TARGET_URL}`;
  }, []);

  const fullURL = useMemo(() => {
    return `${url}?refresh_token=${refresh_token}`;
  }, [url, refresh_token]);

  return fullURL;
};
